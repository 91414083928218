import React, {Component} from 'react'

class Video extends Component {
  render(){
    const { videoSrcURL, videoTitle} = this.props
    return(
      <div style={{height: "100%"}}>
        <iframe
          src={videoSrcURL}
          title={videoTitle}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          width="100%"
          height="100% !important"
        />
      </div>
    )
  }
} 

export default Video