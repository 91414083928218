import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

import Video from "../components/Video"

export class VideoPageTemplate extends Component {
  render() {
    const { seo, hero } = this.props
    return (
      <div className="ppc-main-page">
        <SEO title={seo.title} description={seo.description} />
        <div
          style={{
            backgroundImage: `url(${
              !!hero.image.childImageSharp ? hero.image.childImageSharp.fluid.src : hero.image
              })`,
            backgroundPosition: `center center`,
            backgroundSize: 'cover',
            alignItems: "start",
            padding: "6rem 3rem"
          }}
        >
          <div className="container">
            <div className="video">
              <Video
                videoSrcURL={hero.videourl}
                videoTitle={hero.videoTitle}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

VideoPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
}

const VideoPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <VideoPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
      />
    </Layout>
  )
}

VideoPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default VideoPage

export const pageQuery = graphql`
  query VideoPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "video-page" } }) {
      frontmatter {
        seo {
          title
          description
        }
        hero {
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          videourl
        }
      }
    }
  }
`

